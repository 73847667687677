::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Styles for the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  /* Styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 0px;
  }
  
  /* Styles for the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
.date-picker input{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.cr-app-wrap{
    display: flex;
    // margin-left: -10px;
    // flex-wrap: wrap;
    &-1{
        // margin-left: 10px;
        flex: 0 0 33.3%;
        display: flex;
        align-items: stretch;
        box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    }
    &-2{
        flex: 0 0 66.6%;
        font-size: 18px;
        display: flex;
        align-items: stretch;
    }
}
.page4-wrap {
    background: rgb(204,204,204); 
}
.page4 {
    background: white;
    display: block;
    margin: 0 auto;
    font-size: 19px;
    font-family: "Times New Roman", Times, serif;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    padding: 75px 60px 75px 115px
}

.page4.size {  
    width: 21cm;
    height: 29.7cm; 
}
  
@media print {
body, page4 {
    margin: 0;
    box-shadow: 0;
}
}